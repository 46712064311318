import { Layout } from 'react-grid-layout'
import { BaseUser, Nil } from '@pbt/pbt-ui-components'

import {
  LandingLayoutBreakpoints,
  LandingType,
  WidgetRecordsAssignedMode,
  WidgetWidthType,
} from '~/constants/landingConstants'

import { Invoice } from './finance'
import { DiagnoseHistoryItem } from './medicalHistory'
import { Prescription } from './prescriptions'
import { Reminder } from './reminders'
import { ProblemLogEnumReference } from './soap'
import { Task } from './tasks'
import { VitalRecord, VitalValue } from './vitals'

export type LandingLayout = Partial<Record<LandingLayoutBreakpoints, Layout[]>>

export type LandingWidget = {
  alias: string
  id: string
}

export type LandingSettingsItem = {
  assignedMode: WidgetRecordsAssignedMode
}

export type LandingSettings = Record<string, LandingSettingsItem>

export type WidgetsData<T = any, K = any> = {
  meta?: K
  page: {
    data: T[]
    totalCount: number
  }
}

export type WidgetsDataParams = {
  landingType: LandingType
  pageSize?: number
  patientId?: string | Nil
  quiet: boolean
}

export type WidgetColumnRow = Record<string, any> & {
  className?: string
  id?: string
}

export type WidgetColumn<T extends WidgetColumnRow = WidgetColumnRow> = {
  component?: React.JSXElementConstructor<any>
  id: string
  label?: string
  prop?: keyof T | ((item: T & { dateTitle?: string }) => any)
  width?: number
  widthMap?: Record<WidgetWidthType, number>
  widthTypes?: WidgetWidthType[]
}

export type TasksWidgetColumn = WidgetColumn<Task>

export enum DiagnosisSnapshotItemType {
  DIAGNOSIS_LOG = 'DIAGNOSIS_LOG',
  PROBLEM_DIAGNOSIS_LOG = 'PROBLEM_DIAGNOSIS_LOG',
  PROBLEM_FINDING_LOG = 'PROBLEM_FINDING_LOG',
  DIAGNOSIS_HISTORY = 'DIAGNOSIS_HISTORY',
}

export type DiagnosisSnapshotItem = DiagnoseHistoryItem & {
  businessId: string | Nil
  description: string
  descriptionTranslation: string
  enums?: ProblemLogEnumReference[]
  locationId: string
  soapId: string
  statusId?: string
  type?: DiagnosisSnapshotItemType
  vet: BaseUser | null
}

export type MasterProblemsSnapshotItem = DiagnosisSnapshotItem & {
  children: DiagnosisSnapshotItem[]
}

export type AppointmentSnapshotItem = {}

export type CommunicationSnapshotItem = {
  id: string
  name: string
}

export type EstimateSnapshotItem = {}

export type LabSnapshotItem = {
  id: string
  name: string
}

export type PrepaidItemSnapshotItem = {
  id: string
  name: string
}

export type ReminderSnapshotItem = Reminder & {}

export type PrescriptionSnapshotItem = {
  businessId: string | Nil
  controlled: boolean
  date: string
  id: string
  invoice: Pick<Invoice, 'posted' | 'state' | 'id'> | Nil
  invoiceId: string
  isOTC: boolean
  masterName: string
  name: string
  prescription: Prescription
  soapId: string
  state: string
  type: string
}

export type VaccineSnapshotItem = {
  id: string
  name: string
}

export type VitalSnapshotItem = Record<
  keyof VitalValue,
  VitalRecord<number | string>[]
>
