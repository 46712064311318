import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import { getInfoAboutEnums } from '~/store/reducers/problems'
import { DiagnosisSnapshotItem, ProblemLogEnumReference } from '~/types'

interface ProblemHistoryEnumInfoProps {
  log: DiagnosisSnapshotItem
}

const ProblemHistoryEnumInfo = ({ log }: ProblemHistoryEnumInfoProps) => {
  const parentHistoryEnums =
    log.enums?.filter((enumLog) => !enumLog.parentEnumValueId) || []
  const parentHistoryEnumsIds = parentHistoryEnums.map((item) => item.enumId)

  const parentHistoryEnumsInfo = useSelector(
    getInfoAboutEnums(parentHistoryEnumsIds),
  )

  const getEnumInfo = (enumRef: ProblemLogEnumReference) => ({
    problem: LanguageUtils.getTranslatedFieldName(
      parentHistoryEnumsInfo[enumRef.enumId].enumProblem,
    ),
    problemValue: LanguageUtils.getTranslatedFieldName(
      parentHistoryEnumsInfo[enumRef.enumId].enumProblemValuesMap[
        enumRef.enumValueId
      ],
    ),
    problemNotes: enumRef.notes,
  })

  return (
    <>
      {Boolean(parentHistoryEnums?.length) && (
        <>
          {parentHistoryEnums.map((enumRef: ProblemLogEnumReference) => {
            const { problem, problemValue, problemNotes } = getEnumInfo(enumRef)
            return (
              <Grid key={`${problem}-${problemValue}`} width="100%">
                <Text strong component="span" variant="body2">
                  {problem}:
                </Text>
                <Text component="span" ml={0.5} variant="body2">
                  {problemValue}
                </Text>
                <Text ml={2} variant="body2">
                  {problemNotes}
                </Text>
              </Grid>
            )
          })}
        </>
      )}
    </>
  )
}

export default ProblemHistoryEnumInfo
