import React, { useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { ClickAwayListener, Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateUtils, Text } from '@pbt/pbt-ui-components'
import { ImportedHistory as HistoryIcon } from '@pbt/pbt-ui-components/src/icons'

import { MasterProblemsSnapshotItem } from '~/types'

import ProblemHistoryPanel from './ProblemHistoryPanel'

interface ProblemsDateCellProps {
  clientId: string
  item: MasterProblemsSnapshotItem
  patientId: string
}

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      fontSize: theme.typography.pxToRem(28),
      color: theme.colors.inactiveBackground,
      cursor: 'pointer',
    },
    cellChild: {
      height: '100%',
    },
    tooltip: {
      backgroundColor: theme.colors.tableBackground,
      width: '320px',
      maxWidth: '320px',
      maxHeight: '600px',
      overflowY: 'auto',
      overflowX: 'hidden',
      borderRadius: '4px',
      padding: '16px',
      boxShadow: theme.constants.blockShadow,
    },
  }),
  { name: 'ProblemsDateCell' },
)

const ProblemsDateCell = ({
  item,
  clientId,
  patientId,
}: ProblemsDateCellProps) => {
  const classes = useStyles()

  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid item className={classes.cellChild}>
        <Dotdotdot clamp={1}>
          <Text strong variant="body2">
            {DateUtils.formatDate(item.date) || ''}
          </Text>
        </Dotdotdot>
      </Grid>
      {item.children.length > 1 && (
        <Grid container item className={classes.cellChild} width="auto">
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Tooltip
              disableFocusListener
              disableHoverListener
              disableTouchListener
              PopperProps={{
                disablePortal: true,
              }}
              classes={{ tooltip: classes.tooltip }}
              open={tooltipOpen}
              title={
                <ProblemHistoryPanel
                  clientId={clientId}
                  patientId={patientId}
                  problemLogs={item.children}
                />
              }
              onClose={() => setTooltipOpen(false)}
            >
              <HistoryIcon
                className={classNames(classes.icon)}
                onClick={() => setTooltipOpen(true)}
              />
            </Tooltip>
          </ClickAwayListener>
        </Grid>
      )}
    </Grid>
  )
}

export default ProblemsDateCell
