import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import PuiButtonGroup, {
  PuiButtonGroupItem,
} from '~/components/common/buttons/PuiButtonGroup'
import i18n from '~/locales/i18n'

import ClientMembershipSavingsBanner from './ClientMembershipSavingsBanner'
// @ts-ignore
import MembershipTableLoyaltyPointsView from './loyalty-points/MembershipTableLoyaltyPointsView'
import MembershipTablePatientsView from './patients/MembershipTablePatientsView'
import MembershipTablePaymentsView from './payments/MembershipTablePaymentsView'

const useStyles = makeStyles(
  (theme) => ({
    buttonGroup: {
      marginBottom: theme.spacing(2),
    },
    tableContainer: {
      [theme.breakpoints.up('md')]: {
        minWidth: 1000,
      },
    },
  }),
  { name: 'MembershipTableComponent' },
)

enum MembershipTableRoutes {
  PATIENTS = '',
  LOYALTY_POINTS = 'loyalty-points',
  PAYMENTS = 'payments',
}

const MembershipTableLabels = {
  [MembershipTableRoutes.PATIENTS]: i18n.t('Common:PATIENTS'),
  [MembershipTableRoutes.LOYALTY_POINTS]: i18n.t('Common:LOYALTY_POINTS'),
  [MembershipTableRoutes.PAYMENTS]: i18n.t('Common:PAYMENTS.PAYMENTS'),
}

const MembershipTableComponents = {
  [MembershipTableRoutes.PATIENTS]: MembershipTablePatientsView,
  [MembershipTableRoutes.LOYALTY_POINTS]: MembershipTableLoyaltyPointsView,
  [MembershipTableRoutes.PAYMENTS]: MembershipTablePaymentsView,
}

const MembershipTableTabItems = [
  {
    route: MembershipTableRoutes.PATIENTS,
    name: MembershipTableLabels[MembershipTableRoutes.PATIENTS],
  },
  // {
  //   route: MembershipTableRoutes.LOYALTY_POINTS,
  //   name: MembershipTableViews[MembershipTableRoutes.LOYALTY_POINTS],
  // },
  {
    route: MembershipTableRoutes.PAYMENTS,
    name: MembershipTableLabels[MembershipTableRoutes.PAYMENTS],
  },
]

interface MembershipTableComponentProps {
  clientId: string
}

const MembershipTableComponent = ({
  clientId,
}: MembershipTableComponentProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { currentTable = MembershipTableRoutes.PATIENTS } = useParams<{
    currentTable: MembershipTableRoutes
  }>()
  const { t } = useTranslation('Common')

  const rootMembershipRoute = `/membership/${clientId}/`

  const onViewChange = ({ route }: PuiButtonGroupItem) =>
    navigate(route ? `${rootMembershipRoute}${route}` : rootMembershipRoute)

  const TableComponent = MembershipTableComponents[currentTable]

  return (
    <>
      <ClientMembershipSavingsBanner clientId={clientId} mb={2} />
      <Text mb={0.5} variant="h1">
        {t('Common:MEMBERSHIP')}
      </Text>
      <PuiButtonGroup
        className={classes.buttonGroup}
        items={MembershipTableTabItems}
        selectedItem={MembershipTableLabels[currentTable]}
        onItemSelected={onViewChange}
      />
      <Grid container item className={classes.tableContainer} flex={1}>
        <TableComponent clientId={clientId} />
      </Grid>
    </>
  )
}

export default MembershipTableComponent
